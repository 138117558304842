<template>
  <div>
    <MainCard v-if="!isLoadingTable">
      <template v-slot:body>
        <DataTablePagination
          :page="page"
          :total-items="totalItems"
          :headers="headers"
          :items="certificates"
          :search="search"
          title="Sertifikat Guru"
          subtitle="Halaman untuk menambahkan sertifikat"
          add-btn="Tambah Data"
          :is-loading="isLoadingData"
          :total-pages.sync="totalPages"
          @add-item="showFormAdd"
          @edit-item="showFormEdit"
          @delete-item="confirmDestroy"
          @change-page="paginationHandler"
          @live-search="searchHandler"
          @show-detail="detailCertificate"
        >
        </DataTablePagination>
      </template>
    </MainCard>
    <SkeletonLoaderTable v-else></SkeletonLoaderTable>
    <ModalDialog
      :visible="modalDialog"
      :header="dialog ==='add'? 'Tambah Sertifikat Guru': 'Edit Sertifikat Guru'"
      :text-button="dialog === 'add' ? 'Tambah': 'Update'"
      :is-loading-button="isLoadingButton"
      :is-disabled-button="!formValid || certificate.file === null"
      @close-button="modalDialog = false"
      @handler-button="handlerButton"
      @close-modal="modalDialog = false"
    >
      <template v-slot:body>
        <div>
          <v-text-field
            v-model="certificate.name"
            label="Nama File"
            outlined
            dense
          >
          </v-text-field>
        </div>
        <div>
          <v-textarea
            v-model="certificate.description"
            label="Deskripsi File"
            outlined
            dense
          ></v-textarea>
        </div>
        <div>
          <FileInput
            v-if="dialog =='add'"
            v-model="initialFile"
            :value="certificate.file"
            :prependicon="icons.mdiImageMultipleOutline"
            :message="message"
            label="File"
            @input="getFile"
          ></FileInput>
          <FileInput
            v-else-if="dialog =='edit'"
            v-model="initialFile"
            dense
            outlined
            :prependicon="icons.mdiImageMultipleOutline"
            label="File"
            @input="getFile"
          >
          </FileInput>
        </div>
      </template>
    </ModalDialog>
    <ModalDialog
      :visible="modalCertificate"
      header="Sertifikat"
      :hidden-actions="false"
      @close-modal="modalCertificate = false"
    >
      <template
        v-slot:body
      >
        <div
          class="d-flex py-3 align-center justify-space-between"
        >
          <div>
            <p class="mb-0 font-semibold">
              {{ certificateList.name }}
            </p>
            <span>Nomor : {{ certificateList.description }}</span>
          </div>
          <div>
            <v-chip
              v-if="checkExtension(certificateList.file) === 'pdf'"
              color="success"
              class="v-chip-light-bg success--text"
              :href="certificateList.file"
              target="_blank"
            >
              Lihat Sertifikat
            </v-chip>
            <v-chip
              v-if="checkExtension(certificateList.file) !== 'pdf'"
              color="success"
              class="v-chip-light-bg success--text"
              @click="showCertificate(certificateList.file)"
            >
              Lihat Sertifikat
            </v-chip>
          </div>
        </div>
      </template>
    </ModalDialog>
    <ImageOverlay
      :visible="showImageCertificate"
      :width="800"
      @close="showImageCertificate = false"
    >
      <template v-slot:body>
        <v-card>
          <v-btn
            class="close-image"
            color="white"
            icon
            @click="closeShowCertificate"
          >
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
          <v-img
            :src="certificate.file"
            transition="scroll-y-transition"
            min-width="100"
            max-width="700"
          ></v-img>
        </v-card>
      </template>
    </ImageOverlay>
    <ConfirmDialog
      :visible="confirmDialog"
      :is-loading-button="isLoadingButton"
      header="Hapus Sertifikat Guru"
      @close-button="confirmDialog = false"
      @destroy-button="destroy"
    >
    </ConfirmDialog>
  </div>
</template>

<script>
import ConfirmDialog from '@/views/components/ConfirmDialog.vue'
import DataTablePagination from '@/views/components/DataTablePagination.vue'
import FileInput from '@/views/components/FileInput.vue'
import ImageOverlay from '@/views/components/ImageOverlay.vue'
import MainCard from '@/views/components/MainCard.vue'
import ModalDialog from '@/views/components/ModalDialog.vue'
import SkeletonLoaderTable from '@/views/components/SkeletonLoaderTable.vue'
import { mdiClose, mdiImageMultipleOutline } from '@mdi/js'

export default {
  name: 'Major',
  components: {
    DataTablePagination,
    ModalDialog,
    ConfirmDialog,
    MainCard,
    SkeletonLoaderTable,
    ImageOverlay,
    FileInput,
  },
  data() {
    return {
      message: 'File  .jpg/.jpeg/.png/.pdf, max. 2MB',
      icons: { mdiImageMultipleOutline, mdiClose },
      employee_uuid: '',
      page: 1,
      isLoadingData: false,
      isLoadingButton: false,
      isLoadingTable: true,
      totalItems: 10,
      totalPages: 0,
      search: '',
      dialog: '',
      service: 'certificate',
      certificates: [],
      certificateList: {},
      students: [],
      student_uuid: '',
      certificate: {
        name: '',
        file: null,
        description: '',
      },
      modalDialog: false,
      confirmDialog: false,
      showImageCertificate: false,
      imageOverlay: false,
      modalCertificate: false,
      headers: [
        { text: 'No', value: 'index' },
        { text: 'Nama File', value: 'name' },
        { text: 'Deskripsi', value: 'description' },
        { text: 'File Certificate', value: 'detail' },
        { text: 'Aksi', value: 'actions' },
      ],
      filterQuery: {
        search: '',
      },
      formValid: false,
    }
  },
  computed: {
    initialFile: {
      get() {
        if (typeof this.certificate.file === 'string') {
          return new File([this.certificate.file], this.certificate.file, { type: 'text/plain' })
        }

        return this.certificate.file
      },
      set() {
        return this.certificate.file
      },
    },
  },
  watch: {
    certificate: {
      handler() {
        const valid = []
        const requiredField = ['name', 'description']
        Object.entries(this.certificate).forEach(([key, item]) => {
          if (requiredField.includes(key)) valid.push(!!(item && item.length))
        })
        this.formValid = !valid.includes(false)
      },
      deep: true,
    },
    page: {
      handler() {
        this.getCertificate(this.filterQuery)
      },
    },
  },
  async mounted() {
    this.getEmployeeUuid()
    await this.getCertificate()
  },
  methods: {
    resetForm() {
      this.certificate.name = ''
      this.certificate.description = ''
      this.certificate.file = null
    },
    getFile(data) {
      this.certificate.file = data
    },
    getEmployeeUuid() {
      this.employee_uuid = JSON.parse(localStorage.getItem('user')).user.details[0].uuid
    },
    async showFormAdd() {
      this.dialog = 'add'
      this.modalDialog = true
      this.resetForm()
    },

    async getCertificate(params) {
      this.isLoadingData = true
      await this.$services.ApiServices.list(this.service, {
        ...params,
        page: this.page,
        employee_uuid: this.employee_uuid,
      }).then(
        ({ data }) => {
          this.certificates = data.data.map((certificate, index) => ({
            ...certificate,
            index: index + 1,
          }))
          this.totalItems = data.meta.total
          this.totalPages = data.meta.last_page
        },
        err => console.error(err),
      )
      this.isLoadingTable = false
      this.isLoadingData = false
    },
    async add() {
      this.isLoadingButton = true
      this.formData = new FormData()
      this.formData.append('employee_uuid', this.employee_uuid)
      this.formData.append('file', this.certificate.file)
      this.formData.append('name', this.certificate.name)
      this.formData.append('description', this.certificate.description)
      if (this.certificate.file === null) {
        this.showSnackbar({
          text: 'file sertifikat tidak boleh kosong!! ',
          color: 'error',
        })
        this.isLoadingButton = false

        return
      }
      await this.$services.ApiServices.add(this.service, this.formData).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.isLoadingButton = false
          this.modalDialog = false
        },
        err => {
          console.error(err)
          this.isLoadingButton = false
        },
      )
      await this.getCertificate(this.filterQuery)
      this.resetForm()
    },
    async showFormEdit(uuid) {
      this.dialog = 'edit'
      this.certificate.student_uuid = uuid
      this.certificate.uuid = uuid
      await this.$services.ApiServices.get(this.service, uuid).then(({ data }) => {
        this.certificate = data.data
      })
      this.modalDialog = true
    },
    async update() {
      this.isLoadingButton = true
      this.formData = new FormData()
      this.formData.append('name', this.certificate.name)
      this.formData.append('description', this.certificate.description)
      this.formData.append('file', this.certificate.file)
      if (typeof this.certificate.file === 'string' || this.certificate.file === null) {
        this.formData.delete('file')
      }
      await this.$services.ApiServices.update(this.service, this.formData, this.certificate.uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.isLoadingButton = false
          this.modalDialog = false
          this.resetForm()
        },
        err => {
          this.isLoadingButton = false
        },
      )
      await this.getCertificate(this.filterQuery)
    },
    handlerButton() {
      if (this.dialog === 'add') this.add()
      else this.update()
    },
    confirmDestroy(uuid) {
      this.confirmDialog = true
      this.certificate.uuid = uuid
    },
    async destroy() {
      this.isLoadingButton = true
      await this.$services.ApiServices.destroy(this.service, this.certificate.uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.isLoadingButton = false
          this.confirmDialog = false
        },
        err => {
          this.isLoadingButton = false
        },
      )
      await this.getCertificate(this.filterQuery)
    },
    async detailCertificate(uuid) {
      this.modalCertificate = true
      await this.$services.ApiServices.get(this.service, uuid).then(({ data }) => {
        this.certificateList = data.data
      })
    },
    async showCertificate(file) {
      this.certificate.file = file
      this.modalCertificate = false
      this.showImageCertificate = true
    },
    async closeShowCertificate() {
      this.overlay = false
      this.showImageCertificate = false
    },
    checkExtension(extension) {
      const file = extension.split('.')

      const last = file[file.length - 1]

      return last
    },
    async searchHandler(search) {
      this.filterQuery.search = search
      this.page = 1
      await this.getCertificate(this.filterQuery)
    },
    paginationHandler(pageNumber) {
      this.page = pageNumber
    },

    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
  },
}
</script>

<style>
</style>
